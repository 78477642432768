var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.showDialogLocal),callback:function ($$v) {_vm.showDialogLocal=$$v},expression:"showDialogLocal"}},[(_vm.notificationSettings)?_c('v-card',{class:{ 'text-center': _vm.loading }},[_c('v-toolbar',{staticClass:"pl-2 pr-2 py-0",attrs:{"flat":""}},[(_vm.onboarding)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("notifications"))+" ")]):_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("notification_settings"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showDialogLocal = false}}},[_c('v-icon',[_vm._v("fa-times")])],1)],1),(_vm.loading)?_c('v-progress-circular',{staticClass:"my-5 mx-5",attrs:{"color":"primary","indeterminate":""}}):_c('v-card-text',[(_vm.onboarding)?_c('p',[_vm._v(" "+_vm._s(_vm.$t("notifications_onboarding_1"))),_c('br'),_vm._v(" "+_vm._s(_vm.$t("notifications_onboarding_2"))+" ")]):_vm._e(),_c('v-select',{staticClass:"mb-5",attrs:{"hide-details":"","label":_vm.$t('severity_minimum'),"items":_vm.severityOptions},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',{class:`badge badge-${data.item}`},[_vm._v(" "+_vm._s(_vm.$t(`severity_${data.item}`))+" ")])]}},{key:"item",fn:function(data){return [_c('v-chip',{class:`badge badge-${data.item}`},[_vm._v(" "+_vm._s(_vm.$t(`severity_${data.item}`))+" ")])]}}],null,false,1360236028),model:{value:(_vm.severity),callback:function ($$v) {_vm.severity=$$v},expression:"severity"}}),_c('v-checkbox',{attrs:{"label":_vm.$t('notifications_via_email')},model:{value:(_vm.emailNotifications),callback:function ($$v) {_vm.emailNotifications=$$v},expression:"emailNotifications"}}),(
          _vm.notificationSettings &&
          _vm.notificationSettings.pushSubscriptions.length > 0
        )?_c('div',[_c('p',{staticClass:"mt-5 mb-0"},[_vm._v(_vm._s(_vm.$t("push_subscriptions"))+":")]),_c('v-list',_vm._l((_vm.notificationSettings.pushSubscriptions),function(s){return _c('v-list-item',{key:s.id,staticClass:"px-0"},[_c('v-list-item-content',[_c('v-list-item-title',{class:{
                  'font-weight-bold':
                    s.endpoint === _vm.currentSubscriptionEndpoint,
                }},[_vm._v(" "+_vm._s(s.user_agent)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t("added_on"))+" "+_vm._s(_vm._f("date")(s.subscription_date))+" ")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.cancelSubscription(s)}}},[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("fa-times")])],1)],1)],1)}),1)],1):_vm._e(),(!_vm.currentSubscriptionEndpoint)?_c('v-btn',{attrs:{"block":"","color":"primary","disabled":_vm.noPushSupport,"loading":_vm.submitting},on:{"click":_vm.registerCurrentDevice}},[_vm._v(" "+_vm._s(_vm.$t("register_device"))+" ")]):_vm._e(),(!_vm.pushNotificationsEnabled)?_c('p',{staticClass:"mt-3 mb-0 text-caption text--disabled"},[_vm._v(" "+_vm._s(_vm.$t("notifications_not_supported_or_allowed"))+" ")]):_vm._e()],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }