import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: { name: "NetworkIndex" },
  },
  {
    path: "/network",
    component: () => import("../views/Network"),
    props: true,
    children: [
      {
        path: "/",
        name: "NetworkIndex",
        meta: {
          title: "network",
        },
        component: () => import("../components/Network/Sidebar"),
      },
      {
        path: ":siteId",
        name: "NetworkSiteDetails",
        meta: {
          title: "site_details",
        },
        component: () => import("../components/Network/SiteDetails"),
        props: true,
      },
    ],
  },
  {
    path: "/logs",
    name: "Logs",
    meta: {
      title: "logs",
    },
    component: () => import("../views/Logs"),
  },
  {
    path: "/overview",
    name: "Overview",
    meta: {
      title: "overview",
    },
    component: () => import("../views/Overview"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

export default router;
