<template>
  <v-dialog v-model="showDialog" width="500">
    <v-card class="alert-details-dialog" :class="{ 'text-center': loading }">
      <v-toolbar flat class="pl-2 pr-2 py-0">
        <v-toolbar-title>
          {{ $t("alarm_details") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="showDialog = false">
          <v-icon>fa-times</v-icon>
        </v-btn>
      </v-toolbar>

      <v-progress-circular
        v-if="loading"
        color="primary"
        indeterminate
        class="my-5 mx-5"
      ></v-progress-circular>

      <pre v-if="!loading">{{ alertDetails }}</pre>

      <v-card-actions>
        <v-btn block text @click="showDialog = false">
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import EventBus from "@/services/EventBus";

export default {
  data() {
    return {
      loading: false,
      showDialog: false,
      alertDetails: null,
    };
  },
  mounted() {
    EventBus.$on("alert-details", (alert) => {
      this.openDialogForAlert(alert);
    });
  },
  methods: {
    ...mapActions(["getAlertDetails"]),
    openDialogForAlert(alert) {
      this.loading = true;
      this.showDialog = true;
      this.getAlertDetails(alert.id)
        .then((response) => {
          this.alertDetails = response;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
