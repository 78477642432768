<template>
  <div>
    <v-navigation-drawer v-model="showNavbarLocal" absolute left temporary>
      <div class="logo">
        <img src="/img/logo-kalm.png" />
        <span class="version">{{ version }}</span>
      </div>
      <v-list>
        <v-list-item
          v-for="item in menu"
          :key="item.route"
          :to="{ name: item.route }"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <nav>
      <router-link
        v-for="item in menu"
        :key="item.route"
        :to="{ name: item.route }"
        :title="item.title"
      >
        <v-icon>{{ item.icon }}</v-icon>
      </router-link>
    </nav>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      version: window.VERSIONINFO,
      menu: [
        {
          route: "NetworkIndex",
          title: this.$t("network"),
          icon: "fas fa-gas-pump",
        },
        {
          route: "Logs",
          title: this.$t("logs"),
          icon: "fas fa-clipboard",
        },
        {
          route: "Overview",
          title: this.$t("overview"),
          icon: "fas fa-table",
        },
      ],
    };
  },
  computed: {
    ...mapState({ showNavbar: (state) => state.app.showNavbar }),
    showNavbarLocal: {
      get() {
        return this.showNavbar;
      },
      set(value) {
        // Prevent endless loop
        if (value) {
          return;
        }
        this.toggleNavbar();
      },
    },
  },
  methods: {
    ...mapActions(["toggleNavbar"]),
  },
};
</script>
