import axios from "axios";
import Vue from "vue";
import store from "../store";

export default class Notifications {
  async getPushManager() {
    const registration = await navigator.serviceWorker.register(
      `/service-worker.js`,
      { scope: "/" }
    );
    return registration.pushManager;
  }

  async getSubscription() {
    const pushManager = await this.getPushManager();
    return pushManager.getSubscription();
  }

  async subscribe() {
    // Check for browser support
    if (!this.checkBrowserSupport()) {
      return;
    }

    // Register push manager
    let subscription;
    const pushManager = await this.getPushManager();
    subscription = await pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: this.urlBase64ToUint8Array(
        store.state.setting.applicationSettings.application_vapid
      ),
    });

    // Send subscription to API
    await axios
      .post(
        "subscribe_device",
        { subscription_info: JSON.stringify(subscription) },
        Vue.prototype.$auth.apiHeaders()
      )
      .then(() => {
        // Reload to prevent serviceworker issues
        location.reload();
      });
  }

  async unsubscribe(subscription) {
    return axios
      .post(
        "unsubscribe_device",
        { device_id: subscription.device_id },
        Vue.prototype.$auth.apiHeaders()
      )
      .then(async () => {
        const currentSubscription = await this.getSubscription();
        if (currentSubscription.endpoint === subscription.endpoint) {
          await currentSubscription.unsubscribe();
        }

        // Reload to prevent serviceworker issues
        location.reload();
      });
  }

  urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, "+")
      .replace(/_/g, "/");
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  checkBrowserSupport() {
    // Check for service worker support
    if (!("serviceWorker" in navigator)) {
      console.error("Service worker not available");
      return false;
    }

    // Check for notifications support
    if (!("Notification" in window)) {
      console.error("Notifications not available");
      return false;
    }

    // Check for push manager support
    // Safari on desktop support service worker and notifications but doesn't support push manager
    if (!("pushManager" in ServiceWorkerRegistration.prototype)) {
      console.error("Push manager not available");
      return false;
    }

    return true;
  }

  checkIfDenied() {
    return Notification.permission === "denied";
  }
}
