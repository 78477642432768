export default class Distance {
  static calculate(from, to) {
    if (
      !from ||
      !from.latitude ||
      !from.longitude ||
      isNaN(from.latitude) ||
      isNaN(from.longitude)
    ) {
      throw Error("Invalid coordinate for 'from'");
    }

    if (
      !to ||
      !to.latitude ||
      !to.longitude ||
      isNaN(to.latitude) ||
      isNaN(to.longitude)
    ) {
      throw Error("Invalid coordinate for 'to'");
    }

    const R = 6371; // Radius of the earth in km

    const dLat = this.toRadians(to.latitude - from.latitude);
    const dLon = this.toRadians(to.longitude - from.longitude);

    const lat1R = this.toRadians(from.latitude);
    const lat2R = this.toRadians(to.latitude);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) *
        Math.sin(dLon / 2) *
        Math.cos(lat1R) *
        Math.cos(lat2R);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return Math.round(R * c * 100) / 100;
  }

  static toRadians(degrees) {
    return (degrees * Math.PI) / 180;
  }
}
