export const ADD_ALERT = "ADD_ALERT";
export const ADD_TRANSACTION = "ADD_TRANSACTION";
export const ADD_TRANSACTIONS = "ADD_TRANSACTIONS";
export const DELETE_ALERT = "DELETE_ALERT";
export const MARK_ALERT_AS_READ = "MARK_ALERT_AS_READ";
export const MARK_DOWNTIME_AS_READ = "MARK_DOWNTIME_AS_READ";
export const SAVE_ALERTS = "SAVE_ALERTS";
export const SAVE_APPLICATION_SETTINGS = "SAVE_APPLICATION_SETTINGS";
export const SAVE_ASSETS = "SAVE_ASSETS";
export const SAVE_DOWNTIMES = "SAVE_DOWNTIMES";
export const SAVE_GEOLOCATION = "SAVE_GEOLOCATION";
export const SAVE_LOGS = "SAVE_LOGS";
export const SAVE_LOGS_TOTAL = "SAVE_LOGS_TOTAL";
export const SAVE_NOTIFICATION_SETTINGS = "SAVE_NOTIFICATION_SETTINGS";
export const SAVE_PRICES = "SAVE_PRICES";
export const SAVE_READ_ALERTS = "SAVE_READ_ALERTS";
export const SAVE_READ_DOWNTIMES = "SAVE_READ_DOWNTIMES";
export const SAVE_SELECTED_SITE = "SAVE_SELECTED_SITE";
export const SAVE_SITES = "SAVE_SITES";
export const SAVE_TOKEN = "SAVE_TOKEN";
export const SAVE_TRANSACTIONS = "SAVE_TRANSACTIONS";
export const SAVE_TRANSACTIONS_PAGES = "SAVE_TRANSACTIONS_PAGES";
export const TOGGLE_MAP = "TOGGLE_MAP";
export const TOGGLE_NAVBAR = "TOGGLE_NAVBAR";
export const TOGGLE_PRELOAD_FINISHED = "TOGGLE_PRELOAD_FINISHED";
export const TOGGLE_SEARCH = "TOGGLE_SEARCH";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const UPDATE_ASSET = "UPDATE_ASSET";
export const UPDATE_SELECTED_SITE = "UPDATE_SELECTED_SITE";
export const UPDATE_TRANSACTION = "UPDATE_TRANSACTION";
