import axios from "axios";
import Vue from "vue";
import {
  SAVE_APPLICATION_SETTINGS,
  SAVE_NOTIFICATION_SETTINGS,
} from "../mutation-types";

export default {
  state: {
    applicationSettings: null,
    notificationSettings: null,
  },
  mutations: {
    [SAVE_APPLICATION_SETTINGS](state, payload) {
      state.applicationSettings = payload;
    },
    [SAVE_NOTIFICATION_SETTINGS](state, payload) {
      state.notificationSettings = {
        severity: payload.severity,
        emailNotifications: payload.email_enable,
        pushNotifications: payload.push_enable,
        pushSubscriptions: payload.push_subscriptions,
      };
    },
  },
  actions: {
    getApplicationSettings: ({ commit }) => {
      return axios
        .get("application_settings", Vue.prototype.$auth.apiHeaders())
        .then((response) => {
          commit(SAVE_APPLICATION_SETTINGS, response.data);
        });
    },
    getNotificationSettings: ({ commit }) => {
      return axios
        .get("notification_settings", Vue.prototype.$auth.apiHeaders())
        .then((response) => {
          commit(SAVE_NOTIFICATION_SETTINGS, response.data);
        });
    },
    saveNotificationSettings: ({ commit }, payload) => {
      return axios
        .post(
          "notification_settings",
          {
            severity: payload.severity,
            email_enable: payload.emailNotifications,
            push_enable: payload.pushNotifications,
          },
          Vue.prototype.$auth.apiHeaders()
        )
        .then((response) => {
          commit(SAVE_NOTIFICATION_SETTINGS, response.data);
        });
    },
  },
};
