import axios from "axios";
import dayjs from "dayjs";
import { orderBy, sortBy } from "lodash-es";
import lscache from "lscache";
import Vue from "vue";
import SiteMapper from "@/services/SiteMapper";
import {
  ADD_TRANSACTION,
  ADD_TRANSACTIONS,
  SAVE_ASSETS,
  SAVE_PRICES,
  SAVE_SELECTED_SITE,
  SAVE_SITES,
  SAVE_TOKEN,
  SAVE_TRANSACTIONS,
  SAVE_TRANSACTIONS_PAGES,
  UPDATE_ASSET,
  UPDATE_SELECTED_SITE,
  UPDATE_TRANSACTION,
} from "../mutation-types";

export default {
  state: {
    assets: [],
    prices: [],
    selectedSite: null,
    sites: [],
    transactions: [],
    transactionsPages: null,
  },
  getters: {
    assetById: (state) => (assetId) =>
      state.assets.find((asset) => asset.assetId === assetId),
    assetDynamicObject: (state) => (assetId, key) => {
      const asset = state.assets.find((asset) => asset.assetId === assetId);
      if (!asset.dynamic) {
        return null;
      }
      return asset.dynamic.find((dynamic) => dynamic.key === key);
    },
    assetInfoObject: (state) => (assetId, key) => {
      const asset = state.assets.find((asset) => asset.assetId === assetId);
      if (!asset.info) {
        return null;
      }
      return asset.info.find((info) => info.key === key);
    },
    sitesByName: (state) => sortBy(state.sites, "name"),
    transactionsByDate: (state) =>
      orderBy(state.transactions, ["date"], ["desc"]),
  },
  mutations: {
    [ADD_TRANSACTION](state, payload) {
      state.transactions.push(payload);
    },
    [ADD_TRANSACTIONS](state, payload) {
      state.transactions.push(...payload);
    },
    [SAVE_ASSETS](state, payload) {
      state.assets = payload;
    },
    [SAVE_PRICES](state, payload) {
      state.prices = payload;
    },
    [SAVE_SELECTED_SITE](state, payload) {
      state.selectedSite = payload;
      state.assets = [];
      state.prices = [];
      state.transactions = [];
    },
    [SAVE_SITES](state, payload) {
      state.sites = payload;
    },
    [SAVE_TOKEN](state, payload) {
      let tokens = lscache.get("2fa_tokens") || [];
      tokens = tokens.filter((token) => token.expires >= dayjs().unix());
      tokens.push({
        siteId: state.selectedSite.id,
        token: payload,
        expires: dayjs().add(15, "minutes").unix(),
      });
      lscache.set("2fa_tokens", tokens);
    },
    [SAVE_TRANSACTIONS](state, payload) {
      state.transactions = payload;
    },
    [SAVE_TRANSACTIONS_PAGES](state, payload) {
      state.transactionsPages = payload;
    },
    [UPDATE_ASSET](state, payload) {
      // Find index of payload's asset in the store's assets
      const index = state.assets.findIndex((a) => a.assetId === payload.target);

      // Get asset from store
      const asset = state.assets[index];

      // Exit if asset not found
      if (!asset) {
        return;
      }

      // Initialize dynamic object if not yet present
      if (!asset.dynamic) {
        asset.dynamic = {};
      }

      // Overwrite dynamic object
      asset.dynamic = payload.data;

      // Overwrite asset in store
      Vue.set(state.assets, index, asset);
    },
    [UPDATE_SELECTED_SITE](state, payload) {
      state.selectedSite = payload;
    },
  },
  actions: {
    checkToken: ({ commit, state }) => {
      return axios
        .get(
          `sites/${state.selectedSite.id}/checktoken`,
          Vue.prototype.$auth.apiHeaders()
        )
        .then((response) => {
          if (response.data.status !== "verified") {
            return false;
          }

          const token = response.data.token;
          commit(SAVE_TOKEN, token);

          return true;
        });
    },
    clearSelectedSite: ({ commit }) => {
      commit(SAVE_SELECTED_SITE, null);
    },
    doAction: ({ state }, payload) => {
      return axios
        .post(
          `sites/${state.selectedSite.id}/assets/${payload.assetId}/action`,
          {
            action: payload.action,
            token: payload.token,
          },
          Vue.prototype.$auth.apiHeaders()
        )
        .then((response) => response.data);
    },
    getAssets: async ({ commit, state }) => {
      if (!state.selectedSite) {
        return;
      }

      return axios
        .get(
          `sites/${state.selectedSite.id}/v2/assets`,
          Vue.prototype.$auth.apiHeaders()
        )
        .then((response) => {
          const mapped = [];

          const allDescendants = (
            node,
            level = 1,
            parentId = null,
            parentOptId = null
          ) => {
            if (node.child) {
              for (let i = 0; i < node.child.length; i++) {
                if (node.assetType === "OPT") {
                  parentOptId = node.assetId;
                }
                const currentChild = node.child[i];
                const copyToSave = { ...currentChild, parentOptId };
                delete copyToSave.child;
                mapped.push({
                  ...copyToSave,
                  level,
                  parentId,
                  actions: ["open"],
                });
                allDescendants(
                  currentChild,
                  level + 1,
                  currentChild.assetId,
                  parentOptId
                );
              }
            }
          };

          if (response.data.child && response.data.child.length > 0) {
            response.data.child.forEach((child) => {
              allDescendants(child);
            });
          }

          mapped.forEach((asset) => {
            const assetInState = state.assets.find(
              (a) => a.assetId === asset.assetId
            );
            if (assetInState) {
              asset.dynamic = assetInState.dynamic;
            }
          });

          const sorted = sortBy(mapped, (asset) => {
            if (!asset.info || asset.info.length === 0) {
              return 0;
            }
            const id = asset.info.find((info) => info.key === "id");
            return id ? id.display : 0;
          });

          commit(SAVE_ASSETS, sorted);
        });
    },
    getAssetActions: async ({ state }, assetId) => {
      if (!state.selectedSite) {
        return;
      }

      return axios
        .get(
          `sites/${state.selectedSite.id}/v2/assets/${assetId}/actions`,
          Vue.prototype.$auth.apiHeaders()
        )
        .then((response) => {
          return response.data.actions;
        });
    },
    getBrands: async ({ state }) => {
      return axios
        .get(
          `sites/${state.selectedSite.id}/allbrands`,
          Vue.prototype.$auth.apiHeaders()
        )
        .then((response) => {
          return sortBy(
            response.data.map((brand) => {
              if (!brand || brand === "") {
                return "None";
              }
              return brand;
            })
          );
        });
    },
    getCards: async ({ state }, assetId) => {
      return axios
        .get(
          `sites/${state.selectedSite.id}/assets/${assetId}/cards`,
          Vue.prototype.$auth.apiHeaders()
        )
        .then((response) => {
          return sortBy(response.data);
        });
    },
    getDispensers: async ({ state }) => {
      return axios
        .get(
          `sites/${state.selectedSite.id}/alldispensers`,
          Vue.prototype.$auth.apiHeaders()
        )
        .then((response) => {
          return sortBy(response.data, (dispenser) => {
            return parseInt(dispenser);
          });
        });
    },
    getFuels: async ({ state }) => {
      return axios
        .get(
          `sites/${state.selectedSite.id}/allfuel`,
          Vue.prototype.$auth.apiHeaders()
        )
        .then((response) => {
          return sortBy(
            response.data.map((fuel) => ({
              value: fuel.code,
              text: !!fuel.name && fuel.name !== "" ? fuel.name : "None",
            })),
            "text"
          );
        });
    },
    getOpts: async ({ state }) => {
      return axios
        .get(
          `sites/${state.selectedSite.id}/allopt`,
          Vue.prototype.$auth.apiHeaders()
        )
        .then((response) => {
          return sortBy(
            response.data.map((opt) => {
              if (!opt || opt === "") {
                return "None";
              }
              return opt;
            })
          );
        });
    },
    getPrices: async ({ commit, state }) => {
      return axios
        .get(
          `sites/${state.selectedSite.id}/prices`,
          Vue.prototype.$auth.apiHeaders()
        )
        .then((response) => {
          commit(SAVE_PRICES, response.data);
        });
    },
    getSiteControllerId: async ({ state }) => {
      return axios
        .get(
          `sites/${state.selectedSite.id}/controllerId`,
          Vue.prototype.$auth.apiHeaders()
        )
        .then((response) => {
          return response.data.assetId;
        });
    },
    getSites: async ({ commit, state }) => {
      return axios
        .get("sites.json", Vue.prototype.$auth.apiHeaders())
        .then((response) => {
          const sites = SiteMapper.mapSites(response.data);
          commit(SAVE_SITES, sites);

          // This method is called when an alert is received through websockets,
          // we need to manually override the selected site because it's a copy of the site's old state
          if (!state.selectedSite) {
            return;
          }
          const site = state.sites.find((s) => s.id === state.selectedSite.id);
          commit(UPDATE_SELECTED_SITE, site);
        });
    },
    getTicketTemplate: async ({ state }) => {
      return axios
        .get(
          `sites/${state.selectedSite.id}/ticket-template`,
          Vue.prototype.$auth.apiHeaders()
        )
        .then((response) => response.data);
    },
    getTokenForSelectedSite: async ({ state }) => {
      const tokens = lscache.get("2fa_tokens") || [];
      return tokens.find(
        (token) =>
          token.siteId === state.selectedSite.id &&
          token.expires >= dayjs().unix()
      )?.token;
    },
    getTransactions: async (
      { commit, state },
      { page = 1, filter = {} } = {}
    ) => {
      if (!state.selectedSite) {
        return;
      }

      const query = { ...filter };
      query.page = page;
      query.per_page = 50;
      if (!query.amount1) {
        delete query.operator;
      }
      if (query.search) {
        // Remove leading and trailing spaces
        query.search = query.search.trim();

        // Replace multiple spaces with a single space
        query.search = query.search.replace(/  +/g, " ");
      }

      const filterQuery = Object.keys(query)
        .filter((k) => query[k] !== null)
        .map((k) => `${k}=${query[k]}`)
        .join("&");

      return axios
        .get(
          `sites/${state.selectedSite.id}/transactions?${filterQuery}`,
          Vue.prototype.$auth.apiHeaders()
        )
        .then((response) => {
          commit(SAVE_TRANSACTIONS_PAGES, response.data.meta.total_pages);

          if (page === 1) {
            commit(SAVE_TRANSACTIONS, response.data.data);
            return;
          }

          commit(ADD_TRANSACTIONS, response.data.data);
        });
    },
    getTransactionDetails: async ({ state }, transactionId) => {
      return axios
        .get(
          `sites/${state.selectedSite.id}/transactions/${transactionId}`,
          Vue.prototype.$auth.apiHeaders()
        )
        .then((response) => response.data);
    },
    parseAssetUpdate: ({ commit }, payload) => {
      commit(UPDATE_ASSET, payload);
    },
    parseIncomingTransaction: ({ commit, state }, payload) => {
      payload.addedAsync = true;
      if (state.transactions.some((a) => a.id === payload.id)) {
        commit(UPDATE_TRANSACTION, payload);
        return;
      }
      commit(ADD_TRANSACTION, payload);
    },
    parsePricesUpdate: async ({ commit }, payload) => {
      commit(SAVE_PRICES, payload);
    },
    remapSites: ({ commit, state }) => {
      const sites = [...state.sites];
      commit(SAVE_SITES, SiteMapper.mapSites(sites));
    },
    requestTokenMail: async ({ state }) => {
      return axios
        .post(
          `sites/${state.selectedSite.id}/stepup`,
          null,
          Vue.prototype.$auth.apiHeaders()
        )
        .then((response) => response.data);
    },
    selectSite: ({ commit, state }, siteId) => {
      const site = state.sites.find((s) => s.id === siteId);
      if (!site) {
        console.error(`Site with ID ${siteId} not found`);
        return;
      }
      commit(SAVE_SELECTED_SITE, site);
    },
    updatePrices: async ({ state }, payload) => {
      return axios
        .post(
          `sites/${state.selectedSite.id}/prices?token=${payload.token}`,
          payload,
          Vue.prototype.$auth.apiHeaders()
        )
        .then((response) => response.data);
    },
    updateTicketTemplate: ({ state }, payload) => {
      return axios
        .post(
          `sites/${state.selectedSite.id}/ticket-template`,
          payload,
          Vue.prototype.$auth.apiHeaders()
        )
        .then((response) => {
          return response.data.payload.data;
        });
    },
  },
};
