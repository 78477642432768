<template>
  <v-menu
    :offset-y="true"
    max-height="600"
    max-width="400"
    :content-class="alertsSorted.length > 0 ? 'alerts' : 'alerts alerts-empty'"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" class="alerts-button ml-1" v-on="on">
        <v-badge
          v-if="unreadAlerts.length > 0"
          color="red"
          :content="unreadAlerts.length"
        >
          <v-icon>fas fa-bell</v-icon>
        </v-badge>
        <v-icon v-else>fas fa-bell</v-icon>
      </v-btn>
    </template>
    <v-btn v-if="alertsSorted.length > 0" block text @click="markAllAsRead">
      <v-icon x-small class="mr-2">fa-check</v-icon>
      {{ $t("mark_all_as_read") }}
    </v-btn>
    <v-list dense>
      <template v-for="(alert, i) in alertsSorted">
        <v-list-item :key="alert.id" @click="clickAlert(alert)">
          <v-list-item-content>
            <alert
              :alert="alert"
              :margin="1"
              show-site-name
              small-badge
            ></alert>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          v-if="i !== alertsSorted.length - 1"
          :key="`${alert.id}-divider`"
        ></v-divider>
      </template>
      <v-list-item v-if="alertsSorted.length === 0">
        {{ $t("no_unread_alarms") }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { orderBy } from "lodash-es";
import { mapActions, mapGetters } from "vuex";
import EventBus from "@/services/EventBus";
import Alert from "./Alert";

export default {
  components: { Alert },
  computed: {
    ...mapGetters(["unreadAlerts"]),
    alertsSorted() {
      return orderBy(this.unreadAlerts, ["date"], ["desc"]);
    },
  },
  methods: {
    ...mapActions(["markAlertsAsRead"]),
    clickAlert(alert) {
      EventBus.$emit("search-site", alert.site, true);

      // Wait a bit before marking as read to make sure the dropdown isn't open anymore
      setTimeout(() => {
        this.markAlertsAsRead([alert.id]);
      }, 500);
    },
    markAllAsRead() {
      this.unreadAlerts.forEach((alert) => {
        this.markAlertsAsRead([alert.id]);
      });
    },
  },
};
</script>
