import axios from "axios";
import Vue from "vue";
import { SAVE_LOGS, SAVE_LOGS_TOTAL } from "../mutation-types";

export default {
  state: {
    logs: [],
    logsTotal: null,
  },
  mutations: {
    [SAVE_LOGS](state, payload) {
      state.logs = payload;
    },
    [SAVE_LOGS_TOTAL](state, payload) {
      state.logsTotal = payload;
    },
  },
  actions: {
    getLogs: async (
      { commit },
      {
        sortBy = null,
        sortDesc = false,
        page = 1,
        perPage = 20,
        filter = {},
      } = {}
    ) => {
      const query = { ...filter };
      query.sort_by = sortBy;
      query.sort_desc = sortDesc;
      query.page = page;
      query.per_page = perPage;

      const filterQuery = Object.keys(query)
        .filter((k) => query[k] !== null)
        .map((k) => `${k}=${query[k]}`)
        .join("&");

      return axios(
        `logs.json?${filterQuery}`,
        Vue.prototype.$auth.apiHeaders()
      ).then((response) => {
        commit(SAVE_LOGS_TOTAL, response.data.meta.total_results);
        commit(SAVE_LOGS, response.data.data);
      });
    },
  },
};
