<template>
  <div>
    <v-menu :offset-y="true" max-height="600" width="800" class="settings">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" class="settings-button ml-3" v-on="on">
          <v-avatar color="primary" size="40">
            <span class="white--text">{{ initials }}</span>
          </v-avatar>
        </v-btn>
      </template>
      <v-list dense>
        <template>
          <v-list-item @click="changePassword">
            {{ $t("change_password") }}
          </v-list-item>
          <v-list-item @click="showDialog = true">
            {{ $t("notification_settings") }}
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="logout">
            {{ $t("logout") }}
          </v-list-item>
        </template>
      </v-list>
    </v-menu>

    <settings-notifications-dialog
      v-if="showDialog"
      :show-dialog="showDialog"
      @close-dialog="showDialog = false"
    ></settings-notifications-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import SettingsNotificationsDialog from "./SettingsNotificationsDialog";

export default {
  components: { SettingsNotificationsDialog },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    initials() {
      if (
        Vue.prototype.$auth.keycloak.userInfo.given_name &&
        Vue.prototype.$auth.keycloak.userInfo.family_name
      ) {
        return (
          Vue.prototype.$auth.keycloak.userInfo.given_name.charAt(0) +
          Vue.prototype.$auth.keycloak.userInfo.family_name.charAt(0)
        );
      }

      return Vue.prototype.$auth.keycloak.userInfo.preferred_username.charAt(0);
    },
  },
  mounted() {
    // Check if user had settings dialog open and page was reloaded automatically
    if (localStorage.getItem("show_settings")) {
      this.showDialog = true;
      localStorage.removeItem("show_settings");
    }
  },
  methods: {
    changePassword() {
      Vue.prototype.$auth.keycloak.accountManagement();
    },
    logout() {
      Vue.prototype.$auth.keycloak.logout();
    },
  },
};
</script>
