import { mapActions } from "vuex";

export default {
  mounted() {
    Promise.all([this.getAlerts(), this.getSites()]).then(() => {
      this.togglePreloadFinished();
    });
  },
  methods: {
    ...mapActions(["getAlerts", "getSites", "togglePreloadFinished"]),
  },
};
