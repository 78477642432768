<template>
  <v-dialog v-model="showDialogLocal" width="500">
    <v-card v-if="notificationSettings" :class="{ 'text-center': loading }">
      <v-toolbar flat class="pl-2 pr-2 py-0">
        <v-toolbar-title v-if="onboarding">
          {{ $t("notifications") }}
        </v-toolbar-title>
        <v-toolbar-title v-else>
          {{ $t("notification_settings") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="showDialogLocal = false">
          <v-icon>fa-times</v-icon>
        </v-btn>
      </v-toolbar>

      <v-progress-circular
        v-if="loading"
        color="primary"
        indeterminate
        class="my-5 mx-5"
      ></v-progress-circular>

      <v-card-text v-else>
        <p v-if="onboarding">
          {{ $t("notifications_onboarding_1") }}<br />
          {{ $t("notifications_onboarding_2") }}
        </p>
        <v-select
          v-model="severity"
          hide-details
          :label="$t('severity_minimum')"
          :items="severityOptions"
          class="mb-5"
        >
          <template slot="selection" slot-scope="data">
            <v-chip :class="`badge badge-${data.item}`">
              {{ $t(`severity_${data.item}`) }}
            </v-chip>
          </template>
          <template slot="item" slot-scope="data">
            <v-chip :class="`badge badge-${data.item}`">
              {{ $t(`severity_${data.item}`) }}
            </v-chip>
          </template>
        </v-select>
        <v-checkbox
          v-model="emailNotifications"
          :label="$t('notifications_via_email')"
        ></v-checkbox>
        <div
          v-if="
            notificationSettings &&
            notificationSettings.pushSubscriptions.length > 0
          "
        >
          <p class="mt-5 mb-0">{{ $t("push_subscriptions") }}:</p>
          <v-list>
            <v-list-item
              v-for="s in notificationSettings.pushSubscriptions"
              :key="s.id"
              class="px-0"
            >
              <v-list-item-content>
                <v-list-item-title
                  :class="{
                    'font-weight-bold':
                      s.endpoint === currentSubscriptionEndpoint,
                  }"
                >
                  {{ s.user_agent }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t("added_on") }}
                  {{ s.subscription_date | date }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="cancelSubscription(s)">
                  <v-icon color="grey lighten-1">fa-times</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>
        <v-btn
          v-if="!currentSubscriptionEndpoint"
          block
          color="primary"
          :disabled="noPushSupport"
          :loading="submitting"
          @click="registerCurrentDevice"
        >
          {{ $t("register_device") }}
        </v-btn>
        <p
          v-if="!pushNotificationsEnabled"
          class="mt-3 mb-0 text-caption text--disabled"
        >
          {{ $t("notifications_not_supported_or_allowed") }}
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import EventBus from "@/services/EventBus";
import Notifications from "@/services/Notifications";

export default {
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    onboarding: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      submitting: false,
      notifications: null,
      severityOptions: ["warning", "serious", "critical"],
      noPushSupport: false,
      currentSubscriptionEndpoint: null,
    };
  },
  computed: {
    ...mapState({
      notificationSettings: (state) => state.setting.notificationSettings,
    }),
    pushNotificationsEnabled() {
      return this.notifications?.checkBrowserSupport();
    },
    severity: {
      get() {
        return this.notificationSettings.severity;
      },
      set(value) {
        this.saveNotificationSettings({
          ...this.notificationSettings,
          severity: value,
        });
      },
    },
    emailNotifications: {
      get() {
        return this.notificationSettings.emailNotifications;
      },
      set(value) {
        this.saveNotificationSettings({
          ...this.notificationSettings,
          emailNotifications: !!value,
        });
      },
    },
    showDialogLocal: {
      get() {
        return this.showDialog;
      },
      set(value) {
        if (value) {
          return;
        }
        this.$emit("close-dialog");
      },
    },
  },
  mounted() {
    this.notifications = new Notifications();
    if (!this.notifications.checkBrowserSupport()) {
      this.noPushSupport = true;
    }

    this.loadNotificationSettings();
  },
  methods: {
    ...mapActions(["getNotificationSettings", "saveNotificationSettings"]),
    async getCurrentSubscriptionEndpoint() {
      const subscription = await this.notifications?.getSubscription();
      if (!subscription) {
        return;
      }
      this.currentSubscriptionEndpoint = null;
      for (const s of this.notificationSettings.pushSubscriptions) {
        if (s.endpoint === subscription.endpoint) {
          this.currentSubscriptionEndpoint = s.endpoint;
          return;
        }
      }
    },
    loadNotificationSettings() {
      this.loading = true;
      this.getNotificationSettings().then(() => {
        this.loading = false;
        this.getCurrentSubscriptionEndpoint();
      });
    },
    cancelSubscription(subscription) {
      localStorage.setItem("show_settings", 1);
      this.notifications.unsubscribe(subscription);
    },
    registerCurrentDevice() {
      if (this.notifications.checkIfDenied()) {
        EventBus.$emit("toast", this.$t("notifications_disabled"));
        return;
      }

      localStorage.setItem("show_settings", 1);
      this.submitting = true;
      this.notifications.subscribe();

      // Browser might not show the permission request dialog
      // Page will reload if request succeeds, no need to cancel timer then
      setTimeout(() => {
        this.submitting = false;
        EventBus.$emit("toast", this.$t("notifications_disabled"));
      }, 10000);
    },
  },
};
</script>
