<template>
  <header>
    <v-app-bar-nav-icon class="mr-2" @click="toggleNavbar"></v-app-bar-nav-icon>

    <div class="title">
      <router-link
        :to="{ name: 'Home' }"
        class="logo"
        :class="`logo-${$product}`"
      >
        <img :src="`/img/logo-${$product}.png`" />
        <span class="version">{{ version }}</span>
      </router-link>

      <div class="nav" :class="socketsClass">
        <a class="breadcrumb" @click="backToNetwork">
          <span>{{ $t("network") }}</span>
          <v-icon>fa-caret-right</v-icon>
        </a>
        {{ $t($route.meta.title) }}
      </div>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <span
            class="sockets"
            :class="socketsClass"
            @click="refresh"
            v-on="on"
          ></span>
        </template>
        <span>
          {{ sockets ? $t("sockets_connected") : $t("sockets_disconnected") }}
        </span>
      </v-tooltip>
    </div>

    <div class="search">
      <v-btn v-show="!showSearch" icon @click="toggleSearch">
        <v-icon>fa-search</v-icon>
      </v-btn>
      <v-autocomplete
        v-show="showSearch"
        ref="foo"
        v-model="search"
        :items="sitesByName"
        item-text="name"
        item-value="id"
        :filter="customFilter"
        class="mt-0 mr-2 pt-0"
        :label="$t('search_site')"
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              {{ $t("no_sites_found") }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <template v-slot:item="data">
          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ data.item.address | address }}<br />
              <small>{{ data.item.micrelec_id }}</small>
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
      <v-btn v-show="showSearch" icon @click="clearSearch">
        <v-icon>fa-times</v-icon>
      </v-btn>
    </div>

    <header-alerts></header-alerts>

    <settings></settings>
  </header>
</template>

<script>
import $ from "jquery";
import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import EventBus from "@/services/EventBus";
import HeaderAlerts from "./HeaderAlerts";
import Settings from "./Settings";

export default {
  components: {
    HeaderAlerts,
    Settings,
  },
  data() {
    return {
      version: window.VERSIONINFO,
      search: null,
      sockets: null,
    };
  },
  computed: {
    ...mapGetters(["sitesByName"]),
    ...mapState({
      showSearch: (state) => state.app.showSearch,
    }),
    socketsClass() {
      // Don't add class when page just loaded and didn't connect to sockets yet
      if (this.sockets === null) {
        return;
      }

      return this.sockets ? "connected" : "disconnected";
    },
  },
  watch: {
    search(siteId) {
      if (!siteId) {
        return;
      }
      Vue.prototype.$navigate?.openNetworkSiteDetails(siteId);
    },
    showSearch(value) {
      if (!value) {
        return;
      }
      this.$nextTick(() => {
        $(".search input").focus();
      });
    },
  },
  mounted() {
    EventBus.$on("socket-connect", () => {
      this.sockets = true;
    });
    EventBus.$on("socket-disconnect", () => {
      this.sockets = false;
    });
  },
  methods: {
    ...mapActions(["toggleNavbar", "toggleSearch"]),
    customFilter(item, query) {
      query = query.toLowerCase().trim();

      return (
        item.name?.toLowerCase().includes(query) ||
        item.address.address_line?.toLowerCase().includes(query) ||
        item.address.zipcode?.toLowerCase().includes(query) ||
        item.address.locality?.toLowerCase().includes(query) ||
        item.micrelec_id?.toLowerCase().includes(query)
      );
    },
    backToNetwork() {
      this.$router.push({ name: "NetworkIndex" });
    },
    refresh() {
      if (this.sockets) {
        return;
      }

      location.reload();
    },
    clearSearch() {
      this.toggleSearch();
    },
  },
};
</script>
