import Bugsnag from "@bugsnag/js";
import io from "socket.io-client";
import Vue from "vue";
import EventBus from "@/services/EventBus";
import store from "../store";

export default class Sockets {
  socket;
  subscribedSiteId;

  constructor() {
    this.openSockets();

    EventBus.$on("token-refresh", () => {
      this.openSockets();

      if (this.subscribedSiteId) {
        this.subscribeToSite(this.subscribedSiteId);
      }
    });
  }

  openSockets() {
    const token = Vue.prototype.$auth.keycloak.token;

    console.log("Open socket, token", token.substring(0, 6));

    if (this.socket) {
      try {
        this.socket.disconnect();
      } catch (error) {
        console.log("Error on socket disconnect");
        console.log(error);
        Bugsnag.notify(error);
      }
    }

    this.socket = io(process.env.VUE_APP_SOCKET_URL, {
      path: "/ws/socket.io",
      transports: ["websocket"],
      query: `token=${token}&X-Product=${Vue.prototype.$product}`,
      extraHeaders: {
        authorization: `Bearer ${token}`,
      },
    });

    this.socket.on("connect", () => {
      store.dispatch("getAlerts");
      store.dispatch("getSites");
      store.dispatch("getAssets");
      store.dispatch("getTransactions");
      EventBus.$emit("socket-connect");
    });

    this.socket.on("disconnect", () => {
      this.socket = null;
      EventBus.$emit("socket-disconnect");
    });

    this.clearListeners();
    this.addListeners();
  }

  clearListeners() {
    this.socket.off("alert", this.alertListener);
    this.socket.off("transaction", this.transactionListener);
    this.socket.off("asset", this.assetListener);
    this.socket.off("prices", this.pricesListener);
  }

  addListeners() {
    this.socket.on("alert", this.alertListener);
    this.socket.on("transaction", this.transactionListener);
    this.socket.on("asset", this.assetListener);
    this.socket.on("prices", this.pricesListener);
  }

  alertListener(message) {
    console.log("Received alert", message);
    store.dispatch("processAlert", message.data);
    store.dispatch("getSites");
  }

  transactionListener(message) {
    console.log("Received transaction", message);
    store.dispatch("parseIncomingTransaction", message.data);
  }

  assetListener(message) {
    console.log("Received asset update", message);
    store.dispatch("parseAssetUpdate", message);
  }

  pricesListener(message) {
    console.log("Received prices update", message.data);
    store.dispatch("parsePricesUpdate", message.data);
  }

  subscribeToSite(site) {
    if (this.subscribedSiteId && this.subscribedSiteId !== site) {
      this.unsubscribeFromSite();
    }

    this.subscribedSiteId = site;
    console.log("Subscribe to", this.subscribedSiteId);

    try {
      this.socket.emit("subscribe", { siteId: this.subscribedSiteId });
    } catch (error) {
      console.log("Error on socket emit subscribe");
      console.log(error);
      Bugsnag.notify(error);
    }
  }

  unsubscribeFromSite() {
    console.log("Unsubscribe from", this.subscribedSiteId);

    try {
      this.socket.emit("unsubscribe", { siteId: this.subscribedSiteId });
    } catch (error) {
      console.log("Error on socket emit unsubscribe");
      console.log(error);
      Bugsnag.notify(error);
    }

    this.subscribedSiteId = null;
  }
}
