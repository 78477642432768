import Vue from "vue";
import Vuex from "vuex";
import alertModule from "./modules/alertModule";
import appModule from "./modules/appModule";
import logModule from "./modules/logModule";
import settingModule from "./modules/settingModule";
import siteModule from "./modules/siteModule";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  modules: {
    alert: alertModule,
    app: appModule,
    log: logModule,
    setting: settingModule,
    site: siteModule,
  },
});
