<template>
  <div class="alert" @click="openSite">
    <p class="text-truncate" :class="`mb-${margin}`">
      {{ alert.message | alertMessage }}
      <v-btn
        v-if="openDetails && alert.message.toLowerCase() === 'balance mismatch'"
        rounded
        icon
        x-small
        class="text--disabled ml-1"
        @click.stop="openAlertDetails()"
      >
        <v-icon>fa-info-circle</v-icon>
      </v-btn>
    </p>
    <p
      v-if="showReason"
      class="text-truncate text--secondary"
      :class="`mb-${margin}`"
    >
      {{ alert.reason }}
    </p>
    <small
      v-if="showSiteName && alert.site.name"
      class="text-truncate text--secondary d-block"
      :class="`mb-${margin}`"
    >
      {{ alert.site.name }} {{ alert.opt_number }}
    </small>
    <div>
      <v-chip
        :class="[`badge badge-${alert.type}`, smallBadge ? 'badge-small' : '']"
        :small="!smallBadge"
        :x-small="smallBadge"
      >
        {{ $t(`severity_${alert.type}`) }}
      </v-chip>
      <small class="text--disabled" :class="smallBadge ? 'ml-1' : 'ml-2'">
        {{ alert.date | dateTime }}
      </small>
      <small
        v-if="showSiteName && alert.site.address"
        class="text--disabled ml-2"
      >
        {{ alert.site.address | address }}
      </small>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import EventBus from "@/services/EventBus";

export default {
  props: {
    alert: {
      type: Object,
      required: true,
    },
    margin: {
      type: Number,
      required: false,
      default: 0,
    },
    showReason: {
      type: Boolean,
      required: false,
      default: true,
    },
    showSiteName: {
      type: Boolean,
      required: false,
      default: true,
    },
    smallBadge: {
      type: Boolean,
      required: false,
      default: false,
    },
    openDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    openSite() {
      Vue.prototype.$navigate?.openNetworkSiteDetails(this.alert.site.id);
    },
    openAlertDetails() {
      EventBus.$emit("alert-details", this.alert);
    },
  },
};
</script>
