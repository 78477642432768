import Bugsnag from "@bugsnag/js";
import dayjs from "dayjs";
import Keycloak from "keycloak-js";
import Vue from "vue";
import EventBus from "@/services/EventBus";

export default class Auth {
  keycloak;

  async init() {
    this.keycloak = Keycloak({
      url: window.config.VUE_APP_KEYCLOAK_URL,
      realm: process.env.VUE_APP_KEYCLOAK_REALM,
      clientId: process.env.VUE_APP_KEYCLOAK_CLIENTID,
      flow: "hybrid",
      redirectUri: process.env.VUE_APP_KEYCLOAK_REDIRECTURI,
    });

    return this.keycloak
      .init({ onLoad: "login-required" })
      .then((auth) => {
        if (!auth) {
          throw new Error();
        }

        Vue.prototype.$auth = this;
        console.log(
          "Keycloak authenticated, expires at",
          dayjs.unix(this.keycloak.tokenParsed.exp).format()
        );

        this.initTokenRefresh();

        this.initTokenPolling();

        return this.keycloak.loadUserInfo().then((userInfo) => {
          if (process.env.NODE_ENV === "production") {
            Bugsnag.setUser(
              userInfo.sub,
              userInfo.email,
              userInfo.preferred_username
            );
          }
          return userInfo;
        });
      })
      .catch((error) => {
        console.error(error);
        if (process.env.NODE_ENV === "production") {
          Bugsnag.notify(new Error("Authentication failed"));
        }

        this.keycloak.clearToken();
        location.reload();

        throw error;
      });
  }

  initTokenRefresh() {
    setInterval(() => {
      this.keycloak
        .updateToken(5 * 60) // Refresh token if current token expires in 5 minutes or less
        .then((refreshed) => {
          if (!refreshed) {
            return;
          }

          console.log(
            "Token refreshed, expires at",
            dayjs.unix(this.keycloak.tokenParsed.exp).format()
          );

          EventBus.$emit("token-refresh");
        })
        .catch((error) => {
          console.error(error);
          if (process.env.NODE_ENV === "production") {
            Bugsnag.notify(new Error("Token refresh failed"));
          }

          this.keycloak.clearToken();
          location.reload();

          throw error;
        });
    }, 4 * 60 * 1000); // Execute every 4 minutes
  }

  initTokenPolling() {
    setInterval(() => {
      if (!this.keycloak.authenticated || this.keycloak.isTokenExpired()) {
        location.reload();
      }
    }, 2000);
  }

  apiHeaders() {
    return {
      headers: {
        Authorization: `Bearer ${this.keycloak.token}`,
      },
    };
  }
}
