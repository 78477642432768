import axios from "axios";
import lscache from "lscache";
import Vue from "vue";
import {
  ADD_ALERT,
  DELETE_ALERT,
  MARK_ALERT_AS_READ,
  SAVE_ALERTS,
  SAVE_READ_ALERTS,
  UPDATE_ALERT,
} from "../mutation-types";

export default {
  state: {
    alerts: [],
    readAlerts: [],
  },
  getters: {
    alertsForAsset: (state) => (assetId) =>
      state.alerts.filter((a) => a.assetId === assetId),
    alertsForSite: (state) => (siteId) =>
      state.alerts.filter((a) => a.site.id === siteId),
    unreadAlerts: (state) =>
      state.alerts.filter((a) => !state.readAlerts.includes(a.id)),
  },
  mutations: {
    [ADD_ALERT](state, payload) {
      state.alerts.push(payload);
    },
    [DELETE_ALERT](state, payload) {
      const index = state.alerts.findIndex((a) => a.id === payload.id);
      if (index < 0) {
        return;
      }
      state.alerts.splice(index, 1);
    },
    [MARK_ALERT_AS_READ](state, payload) {
      state.readAlerts.push(payload.id);
    },
    [SAVE_ALERTS](state, payload) {
      state.alerts = payload;
    },
    [SAVE_READ_ALERTS](state, payload) {
      state.readAlerts = payload;
    },
    [UPDATE_ALERT](state, payload) {
      const index = state.alerts.findIndex((a) => a.id === payload.id);
      if (index < 0) {
        return;
      }
      Vue.set(state.alerts, index, payload);
    },
  },
  actions: {
    getAlerts: async ({ commit }) => {
      return axios
        .get("alerts.json", Vue.prototype.$auth.apiHeaders())
        .then((response) => {
          commit(SAVE_ALERTS, response.data);

          // Remove read alerts that don't exist anymore
          const readAlerts = lscache.get("read_alerts");
          if (!readAlerts) {
            return;
          }
          lscache.set(
            "read_alerts",
            readAlerts.filter((id) => response.data.some((a) => a.id === id))
          );

          commit(SAVE_READ_ALERTS, readAlerts);
        });
    },
    getAlertDetails: async (_, alertId) => {
      return axios
        .get(`alerts/${alertId}/balance`, Vue.prototype.$auth.apiHeaders())
        .then((response) => response.data);
    },
    markAlertsAsRead: ({ commit, state }, alertIds) => {
      alertIds.forEach((alertId) => {
        const alert = state.alerts.find((a) => a.id === alertId);
        if (!alert) {
          console.error(`Alert with ID ${alertId} not found`);
          return;
        }
        commit(MARK_ALERT_AS_READ, alert);
      });

      // Check local storage for read alerts and add the passed alert to the list
      // Use a Set to prevent duplicates
      const readAlerts = [...new Set(lscache.get("read_alerts") ?? [])];
      readAlerts.push(...alertIds);
      lscache.set("read_alerts", readAlerts);
    },
    processAlert: ({ commit, state }, alert) => {
      if (alert.type === "none") {
        commit(DELETE_ALERT, alert);

        // Remove alert from read alerts if present
        const readAlerts = lscache.get("read_alerts");
        if (!readAlerts) {
          return;
        }
        lscache.set(
          "read_alerts",
          readAlerts.filter((a) => a !== alert.id)
        );
        return;
      }

      if (state.alerts.some((a) => a.id === alert.id)) {
        commit(UPDATE_ALERT, alert);
        return;
      }

      commit(ADD_ALERT, alert);
    },
  },
};
