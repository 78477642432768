<template>
  <div class="downtimes">
    <v-alert
      v-for="downtime in downtimesSorted"
      :key="downtime.id"
      dismissible
      type="warning"
      width="500"
      @input="close(markDowntimeAsRead(downtime.id))"
    >
      {{ downtime.reason }}<br />
      <small>{{ downtime.date | dateTime }}</small>
    </v-alert>
  </div>
</template>

<script>
import { orderBy } from "lodash-es";
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["unreadAndUpcomingDowntimes"]),
    downtimesSorted() {
      return orderBy(this.unreadAndUpcomingDowntimes, ["date"], ["desc"]);
    },
  },
  mounted() {
    this.getDowntimes();

    setInterval(this.getDowntimes, 1000 * 60);
  },
  methods: {
    ...mapActions(["getDowntimes", "markDowntimeAsRead"]),
  },
};
</script>
