<template>
  <v-dialog
    v-model="showDialog"
    width="400"
    :persistent="step === 'email' || step === 'action'"
  >
    <v-card v-if="action">
      <v-toolbar flat class="pl-2 pr-2 py-0">
        <v-toolbar-title>
          {{ $t(`actions_${action}`) }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text class="pb-0">
        <v-progress-circular
          v-if="loading"
          color="primary"
          indeterminate
          class="mb-5"
        ></v-progress-circular>

        <div v-if="step === 'email'">
          <p v-html="$t('token_verification_1', { email })"></p>
          <p>{{ $t("token_verification_2") }}</p>
        </div>

        <div v-if="step === 'action'">
          <p>{{ $t("submitting_action") }}</p>
        </div>

        <div v-if="step === 'success'">
          <p>{{ $t("request_successful") }}</p>
        </div>

        <div v-if="step === 'timeout'">
          <p>{{ $t("timeout") }}</p>
        </div>

        <div v-if="step === 'error'">
          <p>{{ $t("something_went_wrong_try_again") }}</p>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn block text @click="close()">
          {{ step === "email" ? $t("cancel") : $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { mapActions } from "vuex";
import EventBus from "@/services/EventBus";

export default {
  data() {
    return {
      step: null,
      action: null,
      loading: false,
      email: null,
      showDialog: false,
      token: null,
      tokenTimer: null,
    };
  },
  mounted() {
    this.email = Vue.prototype.$auth.keycloak.userInfo.email;

    EventBus.$on(
      "action",
      async (
        action,
        assetId,
        payload,
        callback,
        verificationRequired = true
      ) => {
        if (this.loading) {
          throw "Tried to initialize action while previous still loading";
        }

        this.step = "email";
        this.action = action;
        this.showDialog = true;
        this.loading = true;

        // Execute action if a verification isn't required
        if (!verificationRequired) {
          this.executeAction(
            action,
            assetId,
            payload,
            callback,
            verificationRequired
          );
          return;
        }

        // Execute action if a valid token is available
        if (await this.getTokenForSelectedSite()) {
          this.executeAction(
            action,
            assetId,
            payload,
            callback,
            verificationRequired
          );
          return;
        }

        // Request token if a no valid token available
        await this.requestTokenMail();

        // Dialog is possibly already closed while waiting for requestTokenMail
        if (!this.showDialog) {
          return;
        }

        let secondsDialogVisible = 0;

        this.tokenTimer = setInterval(async () => {
          secondsDialogVisible++;

          if (secondsDialogVisible === 60) {
            clearInterval(this.tokenTimer);
            this.loading = false;
            this.step = "timeout";
          }

          if (await this.checkToken()) {
            clearInterval(this.tokenTimer);

            this.executeAction(
              action,
              assetId,
              payload,
              callback,
              verificationRequired
            );
          }
        }, 1000);
      }
    );
  },
  methods: {
    ...mapActions([
      "checkToken",
      "doAction",
      "getTokenForSelectedSite",
      "requestTokenMail",
      "updatePrices",
    ]),
    close() {
      this.showDialog = false;
      this.loading = false;
      clearInterval(this.tokenTimer);
    },

    async executeAction(
      action,
      assetId,
      payload,
      callback,
      verificationRequired
    ) {
      this.step = "action";

      switch (action) {
        case "fuel_prices":
          this.updatePrices({
            ...payload,
            token: verificationRequired
              ? await this.getTokenForSelectedSite()
              : null,
          })
            .then(() => {
              this.step = "success";
            })
            .catch(() => {
              this.step = "error";
            })
            .finally(() => {
              this.loading = false;
            });
          break;
        default:
          this.doAction({
            assetId,
            action,
            token: verificationRequired
              ? await this.getTokenForSelectedSite()
              : null,
          })
            .then(() => {
              this.step = "success";
            })
            .catch(() => {
              this.step = "error";
            })
            .finally(() => {
              this.loading = false;
            });
      }

      if (callback && typeof callback === "function") {
        callback();
      }
    },
  },
};
</script>
