import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import OPT from "../icons/OPT";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "fa",
    values: {
      opt: {
        component: OPT,
      },
    },
  },
  theme: {
    themes: {
      light: {
        primary: "#D70926",
        red: "#AE1022",
      },
    },
  },
});
