<template>
  <v-snackbar
    v-model="showMessage"
    absolute
    top
    rounded="pill"
    timeout="4000"
    :class="error ? 'toast-error' : 'toast-notification'"
  >
    <v-icon v-if="error" small class="mr-2">fa-exclamation-circle</v-icon>
    <v-icon v-else small class="mr-2">fa-check-circle</v-icon>

    <span>{{ message }}</span>
  </v-snackbar>
</template>

<script>
import EventBus from "@/services/EventBus";

export default {
  data() {
    return {
      showMessage: false,
      message: null,
      error: true,
      timeout: null,
    };
  },
  mounted() {
    EventBus.$on("toast", (message, error = true) => {
      this.message = message;
      this.error = error;
      this.showMessage = true;

      if (this.timeout) {
        clearTimeout(this.timeout);
        return;
      }

      this.timeout = setTimeout(() => {
        this.message = null;
        this.showMessage = false;
      }, 4000);
    });
  },
};
</script>
