import dayjs from "dayjs";
import Vue from "vue";

Vue.filter("address", (value) => {
  if (value === null || value === undefined) {
    return "";
  }
  return `${value.address_line}, ${value.locality}`;
});

Vue.filter("date", (value) => {
  if (!value) {
    return "";
  }
  const date = dayjs(value);
  if (!date.isValid()) {
    return value;
  }
  return date.format("YYYY-MM-DD");
});

Vue.filter("time", (value) => {
  if (!value) {
    return "";
  }
  const date = dayjs(value);
  if (!date.isValid()) {
    return value;
  }
  return date.format("HH:mm");
});

Vue.filter("dateTime", (value) => {
  if (!value) {
    return "";
  }
  const date = dayjs(value);
  if (!date.isValid()) {
    return value;
  }
  return date.format("YYYY-MM-DD HH:mm");
});

Vue.filter("money", (value, decimals) => {
  if (value === null || value === undefined || isNaN(value)) {
    return "";
  }
  return Number(value / 1000).toLocaleString("nl-BE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: decimals || 0,
    maximumFractionDigits: decimals || 0,
  });
});

Vue.filter("percentage", (value) => {
  if (value === null || value === undefined || isNaN(value)) {
    return "";
  }
  return `${Number(value).toLocaleString("nl-BE")}%`;
});

Vue.filter("volumeTank", (value) => {
  if (value === null || value === undefined || isNaN(value)) {
    return "";
  }
  return `${Number(value / 1000)} L`;
});

Vue.filter("volumeTransaction", (value, decimals, unit) => {
  if (value === null || value === undefined || isNaN(value)) {
    return "";
  }

  if (unit === null || unit === undefined) {
    return `${Number(value / 100).toLocaleString("nl-BE", {
      minimumFractionDigits: decimals || 0,
      maximumFractionDigits: decimals || 0,
    })} L`;
  }
  return `${Number(value / 100).toLocaleString("nl-BE", {
    minimumFractionDigits: decimals || 0,
    maximumFractionDigits: decimals || 0,
  })} ${unit}`;
});

Vue.filter("weightTransaction", (value) => {
  if (value === null || value === undefined || isNaN(value)) {
    return "";
  }
  return `${Number(value / 100).toLocaleString("nl-BE")} Kg`;
});

Vue.filter("cardref", (value) => {
  if (!value) {
    return;
  }
  return `x${value.toString().slice(-4)}`;
});

Vue.filter("alertMessage", (value) => {
  if (!value) {
    return "";
  }
  const code = value.split("/").pop();
  switch (code) {
    case "doorLeftOpen":
      return "OPT door open";
    case "outOfOrder":
      return "OPT out of order";
    case "paperLength":
      return "Printer paper";
    default:
      return code;
  }
});

Vue.filter("key", (value) => {
  if (!value) {
    return;
  }
  value = value.replaceAll("_", " ");
  return value;
});
