<template>
  <v-app
    :class="[pageClass, { 'show-map': showMap, 'show-search': showSearch }]"
  >
    <v-header v-if="loggedIn"></v-header>

    <v-progress-circular
      v-if="!preloadFinished"
      color="primary"
      indeterminate
      class="preloader"
    ></v-progress-circular>

    <v-container v-if="preloadFinished" fluid>
      <navigation v-if="loggedIn"></navigation>
      <main>
        <router-view />
      </main>
    </v-container>

    <settings-notifications-dialog
      v-if="showDialog"
      onboarding
      :show-dialog="showDialog"
      @close-dialog="showDialog = false"
    ></settings-notifications-dialog>

    <alert-details-dialog></alert-details-dialog>

    <downtimes></downtimes>

    <toast></toast>

    <action-dialog></action-dialog>
  </v-app>
</template>

<script>
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import ActionDialog from "./components/Global/ActionDialog";
import AlertDetailsDialog from "./components/Global/AlertDetailsDialog";
import Downtimes from "./components/Global/Downtimes";
import Header from "./components/Global/Header";
import Navigation from "./components/Global/Navigation";
import SettingsNotificationsDialog from "./components/Global/SettingsNotificationsDialog";
import Toast from "./components/Global/Toast";
import Geolocation from "./services/Geolocation";
import Preloader from "./services/Preloader";
import Sockets from "./services/Sockets";

export default {
  name: "App",
  components: {
    ActionDialog,
    AlertDetailsDialog,
    Downtimes,
    Navigation,
    SettingsNotificationsDialog,
    Toast,
    "v-header": Header,
  },
  extends: Preloader,
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    ...mapState({
      preloadFinished: (state) => state.app.preloadFinished,
      showMap: (state) => state.app.showMap,
      showSearch: (state) => state.app.showSearch,
    }),
    pageClass() {
      if (!this.$route.name) {
        return;
      }
      return `page-${this.$route.name.toLowerCase()}`;
    },
    loggedIn() {
      return !this.$route.meta.public;
    },
  },
  watch: {
    "$route.meta": {
      immediate: true,
      handler({ title }) {
        let doc = "Magview";
        if (this.$productOpt) {
          doc += " OPT";
        }
        if (title) {
          doc += ` – ${this.$t(title)}`;
        }
        document.title = doc;
      },
    },
  },
  mounted() {
    this.getApplicationSettings();

    Vue.prototype.$productAlarms = Vue.prototype.$product === "kalm";
    Vue.prototype.$productOpt = Vue.prototype.$product === "opt";

    Vue.prototype.$geolocation = new Geolocation();
    Vue.prototype.$sockets = new Sockets();

    Vue.prototype.$navigate = {
      openNetworkSiteDetails: (siteId) => {
        if (this.$route.name === "NetworkSiteDetails") {
          if (this.$route.params.siteId === siteId) {
            return;
          }
          this.$router.replace({
            params: { siteId },
          });
        } else {
          this.$router.push({
            name: "NetworkSiteDetails",
            params: { siteId },
          });
        }
      },
    };

    if (!localStorage.getItem("notifications_onboarding_showed")) {
      this.showDialog = true;
      localStorage.setItem("notifications_onboarding_showed", 1);
    }
  },
  methods: {
    ...mapActions(["getApplicationSettings"]),
  },
};
</script>
