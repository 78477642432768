import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import Vue from "vue";

if (process.env.NODE_ENV === "production") {
  Bugsnag.start({
    appVersion: window.VERSIONINFO,
    apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginVue()],
    releaseStage: window.BUGSNAG_RELEASE_STAGE,
    enabledReleaseStages: ["production", "staging"],
    onError: function (event) {
      event.addMetadata("app", {
        product: process.env.VUE_APP_PRODUCT,
      });
    },
  });

  const bugsnagVue = Bugsnag.getPlugin("vue");
  bugsnagVue.installVueErrorHandler(Vue);
}
