import Bugsnag from "@bugsnag/js";
import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common["X-Product"] = process.env.VUE_APP_PRODUCT;

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (process.env.NODE_ENV === "production") {
      const detail = error.response?.data?.detail;
      if (detail) {
        Bugsnag.notify(detail);
      }
    }

    throw error;
  }
);
