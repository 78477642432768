import Distance from "@/services/Distance";
import store from "@/store";

export default class SiteMapper {
  static mapSites(sites) {
    return sites.map((s) => this.mapSite(s));
  }

  static mapSite(site) {
    let distance = null;

    const geolocation = store.state.app.geolocation;

    if (
      geolocation &&
      site.location &&
      site.location.latitude &&
      site.location.longitude
    ) {
      distance = Distance.calculate(geolocation, site.location);
    }

    return {
      ...site,
      distance,
    };
  }
}
